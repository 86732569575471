@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --shark: #1b1b1f;
    --shark-2: #181820;
    --mischka: #d1d1d6;
    --mischka1: #d1d1dd;
    --dove-gray: #727272;
    --cod-gray: #1d1d1d;
    --cod-gray1: #1a1a1a;
    --concrete: #f3f3f3;
    --dodger-blue: #3d7ef5;
    --denim: #1255cc;
    --boston-blue: #428bca;
    --blizzard-blue: #aad5f0;
    --seagull: #75b8e3;
    --mercury: #e5e5e5;
    --mercury1: #e6e6e6;
    --jumbo: #727277;
    --bombay: #b4b7be;
    --vermilion: #ff4d0b;
    --error: #e23f04;
    --puerto-rico: #48c9b0;
    --emperor: #515151;
    --wild-sand: #f4f4f4;
    --alabaster: #f8f8f8;
    --oslo-gray: #959799;
    --gallery: #f0f0f0;
    --gallery2: #eeeeee;
    --mine-shaft: #333333;
    --zombie: #ded492;
    --husk: #b2a346;
    --pink-salmon: #ff9194;
    --purple-heart: #681da8;
    --perano: #aab0f0;
    --bittersweet: #ff7362;
    --anakiwa: #99ccff;
    --tundora: #414141;
    --gray-900: #1b1b1f;
    --athens-gray: #f2f2f7;
    --athens-gray-1: #F2F3F5;

    --sun: #f8b31f;
    --storm-gray: #727284;

    --shakespeare: #44A5CF;
    --shakespeare-light1: rgba(68, 165, 207, 0.1);
    --jelly-bean: #2A7B9E;
    --jelly-bean-light1: rgba(42, 123, 158, 0.6);
    --jelly-bean-light2: rgba(42, 123, 158, 0.1);
    --jelly-bean-light3: rgba(42, 123, 158, 0.08);

    --cerise-red: #DC3456;
    --catskill-white: #E6F0F4;
    --black-haze: #F5F6F6;
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary-foreground: 210 40% 98%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;

    --black1: rgba(0, 0, 0, 0.8);
    --black2: rgba(0, 0, 0, 0.24);
    --black3: rgba(0, 0, 0, 0.12);
    --black4: rgba(0, 0, 0, 0.4);
    --black5: rgba(0, 0, 0, 0.6);

    --white: #fff;
    --white1: rgba(255, 255, 255, 0.8);
    --white2: rgba(255, 255, 255, 0.32);
    --white3: rgba(255, 255, 255, 0.48);
    --white4: rgba(255, 255, 255, 0.12);
    --white5: rgba(255, 255, 255, 0.6);
    --boston-blue-light: rgba(66, 139, 202, 0.12);
    --denim-light: rgba(18, 85, 204, 0.12);
    --vermilion-light: rgba(255, 77, 11, 0.12);
    --bittersweet-light: rgba(255, 115, 98, 0.6);



    --primary: #342aff;

    --secondary: #000000;
    --main-text-color: #000000;

    --custom-weight: 400;
    --page-text-color: #000;
    --page-text-color2: #000;
    --main-border-color: #000;

    --main-border-radius: 4px;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --main-text-color: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer utilities {
  .font-dynamic {
    font-weight: var(--custom-weight);
  }
  .color-text-dynamic {
    color: var(--page-text-color);
  }
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
}

#__next {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.default-transition {
  transition: all 0.3s ease-in-out;
}

.short-transition {
  transition: all 0.15s ease-in-out;
}
.outline-solid{
  outline-style: solid;
}

.default-disabled {
  &:disabled{
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.fileInput > [type='file'] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
}

.fileInput > p {
  font-weight: bold;
}

/* common styles */

@keyframes animation-time-3-1 {
  0% {
    display: block;
    opacity: 1;
  }
  20% {
    display: block;
    opacity: 1;
  }
  24% {
    display: block;
    opacity: 0;
  }
  25% {
    display: none;
    opacity: 0;
  }
  100% {
    display: none;
    opacity: 0;
  }
}

@keyframes animation-time-3-2 {
  0% {
    display: none;
    opacity: 0;
  }
  25% {
    display: block;
    opacity: 0;
  }
  30% {
    display: block;
    opacity: 1;
  }
  50% {
    display: block;
    opacity: 1;
  }
  54% {
    display: block;
    opacity: 0;
  }
  55% {
    display: none;
    opacity: 0;
  }
  100% {
    display: none;
    opacity: 0;
  }
}

@keyframes animation-time-3-3 {
  0% {
    display: none;
    opacity: 0;
  }
  55% {
    display: block;
    opacity: 0;
  }
  60% {
    display: block;
    opacity: 1;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes animation-time-2-2 {
  0% {
    display: none;
    opacity: 0;
  }
  25% {
    display: block;
    opacity: 0;
  }
  30% {
    display: block;
    opacity: 1;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes dotty {
  0% {
    content: '';
  }
  25% {
    content: '.  ';
  }
  50% {
    content: '.. ';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}

.loader-text-3-1 {
  text-align: center;
  margin-top: 24px;
  animation: animation-time-3-1 10s ease-out forwards;
}
.loader-text-3-2 {
  text-align: center;
  margin-top: 24px;
  animation: animation-time-3-2 10s ease-out forwards;
}
.loader-text-3-3 {
  text-align: center;
  margin-top: 24px;
  animation: animation-time-3-3 10s ease-out forwards;
}

.loader-text-2-1 {
  text-align: center;
  margin-top: 24px;
  animation: animation-time-3-1 10s ease-out forwards;
}
.loader-text-2-2 {
  text-align: center;
  margin-top: 24px;
  animation: animation-time-2-2 10s ease-out forwards;
}

.dot-animation {
  position: relative;
}

.dot-animation::after {
  display: inline-block;
  position: absolute;
  animation: dotty steps(1, end) 1.5s infinite;
  content: '';
}
